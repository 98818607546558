<template>
  <v-chip href="javascript:;" :color="attrColor" :small="small">{{ value }}</v-chip>
</template>

<script>
export default {
  props:{
    value: String,
    small: Boolean,
  },
  computed:{
    attrColor(){
      var stat = '';
      switch (this.value) {
        case 'NEW':
          stat = 'primary';
          break;
        case 'ANSWERED':
          stat = 'info';
          break;
        case 'CLOSED':
          stat = 'success';
          break;
        default:
          stat = 'info';
          break;
      }

      return stat;

    }
  }
}
</script>